import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Hero from 'components/hero'
import Header from 'components/Header'
import Footer from 'components/footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Dropdown from 'react-bootstrap/Dropdown'
import AlertTray from '../components/alertTray'
import { SiAtom } from 'react-icons/si'
import { BsDropletFill } from 'react-icons/bs'
import { RiLightbulbFlashFill } from 'react-icons/ri'
import { FaAtom } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import GlobalContextProvider from '../context/global_context_provider'
import { GlobalDispatchContext } from 'context/global_context_provider'
import appInfo from '../static/app_info'
import 'bootstrap/dist/css/bootstrap.css'
import { SignOutIcon } from '@primer/octicons-react'
import { loginUrl, alldataUrl, profileUrl } from 'tools/aws/url'
import Layout from 'components/layout'


const hdataSortOptions = ['Station', 'Unit', 'Cycle', 'Owner', 'Upload']
const keys = [
  'hdata_key',
  'hdata_order',
  'case_key',
  'case_order',
  'report_key',
  'report_order'
]


const ProfilePage = (props) => {
  const dispatch = React.useContext(GlobalDispatchContext)
  const [firstNameInput, setFirstNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [firstNameDisplayed, setFirstNameDisplayed] = useState('')
  const [lastNameDisplayed, setLastNameDisplayed] = useState('')
  const [hdataSortOption, setHdataSortOption] = useState('')
  const [hdataSortOrder, setHdataSortOrder] = useState('')
  const [depSortOption, setDepSortOption] = useState('')
  const [depSortOrder, setDepSortOrder] = useState('')
  const [repSortOption, setRepSortOption] = useState('')
  const [repSortOrder, setRepSortOrder] = useState('')
  const [gotOptions, setGotOptions] = useState(false)
  const url = `https://${process.env.GATSBY_API_DOMAIN}/update`
  const getSortUrl = `https://${process.env.GATSBY_API_DOMAIN}/sort/get`
  const setSortUrl = `https://${process.env.GATSBY_API_DOMAIN}/sort/set`
  useEffect(() => {
    if (props.data.profile) {
      setFirstNameDisplayed(props.data.profile.givenName)
      setLastNameDisplayed(props.data.profile.familyName)
    }
  }, [props.data.profile])
  const call = async (attribute, value) => {
    const params = {
      params: {
        attribute: attribute,
        value: value
      },
      withCredentials: true
    }
    const updateResult = await axios.post(url, {}, params)
  }
  const getSort = async () => {
    const params = { withCredentials: true }
    const result = await axios.get(getSortUrl, {}, params)
    if (result.data.result === 'success') {
      console.log('passed, setting options')
      setHdataSortOption(result.data.options.hdata_key)
      setHdataSortOrder(result.data.options.hdata_order)
      setDepSortOption(result.data.options.case_key)
      setDepSortOrder(result.data.options.case_order)
      setRepSortOption(result.data.options.report_key)
      setRepSortOrder(result.data.options.report_order)
    }
    console.log(result)
  }
  const setSort = async () => {
    console.log('triggered!')
    const sortOptions = {
      hdata_key: hdataSortOption,
      hdata_order: hdataSortOrder,
      case_key: depSortOption,
      case_order: depSortOrder,
      report_key: repSortOption,
      report_order: repSortOrder
    }
    console.log(sortOptions)
    const params = { params: sortOptions, withCredentials: true }
    const result = await axios.get(setSortUrl, params)
    console.log(result)
    dispatch({
      type: 'addAlert',
      toAdd: {
        id: uuidv4(),
        type: 'text',
        text: 'User sort preferences have been updated'
      }
    })
  }
  const submit = async () => {
    console.log('triggrtrf')
    if ( firstNameInput && lastNameInput ){
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'text',
          text: 'You can only update one attribute at a time'
        }
      })
    } else if( !firstNameInput && !lastNameInput ){
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'text',
          text: 'Empty fields; No update performed'
        }
      })
    } else if( firstNameInput && !lastNameInput ){
        await call('given_name', firstNameInput)
        document.cookie = (
          'mco-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;' +
          ` domain=.${process.env.GATSBY_APP_DOMAIN}; path=/;`
        )
        document.cookie = (
          'mco-id-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;' +
          ` domain=.${process.env.GATSBY_APP_DOMAIN}; path=/;`
        )
        window.location.reload()
    } else if( lastNameInput && !firstNameInput ){
        await call('family_name', lastNameInput)
        document.cookie = (
          'mco-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;' +
          ` domain=.${process.env.GATSBY_APP_DOMAIN}; path=/;`
        )
        document.cookie = (
          'mco-id-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;' +
          ` domain=.${process.env.GATSBY_APP_DOMAIN}; path=/;`
        )
        window.location.reload()
    }
  }
  useEffect(()=>{
    console.log('calling get sort on page load')
    getSort()
  }, [])
  return (
        <Container>
          <Row className="justify-content-center mt-5 pb-5">
            <Col xs={12} md={6}>
              <Card>
                <Card.Header as="h5">My Profile</Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>
                          {'First Name: ' + firstNameDisplayed}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter new first name"
                        value={firstNameInput}
                        onChange={(e) => setFirstNameInput(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formLastName" className="pt-2">
                      <Form.Label>
                          {'Last Name: ' + lastNameDisplayed}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter new last name"
                        value={lastNameInput}
                        onChange={(e) => setLastNameInput(e.target.value)}
                      />
                    </Form.Group>
                    <Button className="mt-3" onClick={()=>submit()}>
                      Update Name
                    </Button>
                    <Row className="mt-5">
                      <Col>
                        <Form.Label>
                          Historical Data Sort Field:
                        </Form.Label>
                        <Form.Select
                          onChange={e => {setHdataSortOption(e.target.value)}}
                          value={hdataSortOption}
                        >
                          <option key='sel-no' value=''>
                            -
                          </option>
                          <option key='sel-0' value='station'>
                            Station
                          </option>
                          <option key='sel-1' value='unit'>
                            Unit
                          </option>
                          <option key='sel-2' value='cycle'>
                            Cycle
                          </option>
                          <option key='sel-3' value='owner'>
                            Owner
                          </option>
                          <option key='sel-4' value='upload'>
                            Upload
                          </option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Label>
                          Sort Order:
                        </Form.Label>
                        <Form.Select
                          onChange={e => {
                            setHdataSortOrder(e.target.value)
                          }}
                          value={hdataSortOrder}
                        >
                          <option key='sel-no' value=''>-</option>
                          <option key='sel-0' value='a'>Ascending</option>
                          <option key='sel-1' value='d'>Descending</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>
                          Depletion Sort Field:
                        </Form.Label>
                        <Form.Select
                          onChange={e => {setDepSortOption(e.target.value)}}
                          value={depSortOption}
                        >
                          <option key='sel-no' value=''>
                            -
                          </option>
                          <option key='sel-0' value='station'>
                            Station
                          </option>
                          <option key='sel-1' value='unit'>
                            Unit
                          </option>
                          <option key='sel-2' value='cycle'>
                            Cycle
                          </option>
                          <option key='sel-3' value='owner'>
                            Owner
                          </option>
                          <option key='sel-4' value='upload'>
                            Creation Date
                          </option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Label>
                          Sort Order:
                        </Form.Label>
                        <Form.Select
                          onChange={e => {setDepSortOrder(e.target.value)}}
                          value={depSortOrder}
                        >
                          <option key='sel-no' value=''>-</option>
                          <option key='sel-0' value='a'>Ascending</option>
                          <option key='sel-1' value='d'>Descending</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>
                          Report Sort Field:
                        </Form.Label>
                        <Form.Select
                          onChange={e => {setRepSortOption(e.target.value)}}
                          value={repSortOption}
                        >
                          <option key='sel-no' value=''>
                            -
                          </option>
                          <option key='sel-0' value='station'>
                            Station
                          </option>
                          <option key='sel-1' value='unit'>
                            Unit
                          </option>
                          <option key='sel-2' value='cycle'>
                            Cycle
                          </option>
                          <option key='sel-3' value='owner'>
                            Owner
                          </option>
                          <option key='sel-4' value='upload'>
                            Creation Date
                          </option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Label>
                          Sort Order:
                        </Form.Label>
                        <Form.Select
                          onChange={e => {setRepSortOrder(e.target.value)}}
                          value={repSortOrder}
                        >
                          <option key='sel-no' value=''>-</option>
                          <option key='sel-0' value='a'>Ascending</option>
                          <option key='sel-1' value='d'>Descending</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Button className="mt-3" onClick={()=>setSort()}>
                      Update Sort
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
  )
}

ProfilePage.Layout = Layout

export default ProfilePage
